import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ChangePasswordIcon, FeedBackIcon, HelpAndSupportIcon, LogOutIcon1, ProfileIcon1 } from "../../../common/Icons";
import FeedbackModal from "../../../common/FeedbackModal";
import { convertToInitials } from "../../../Hook/validation";
import { apiHost } from "../../../common/Config";

const CompanyProfileOption = ({ onClick, data,getBankerData }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
    const popoverRef = useRef(null);
    const ImageData = false;
    const navigate = useNavigate();

    const toggleModal = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event) => {
        if (popoverRef.current && !popoverRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    return (
        <div className='relative' ref={popoverRef}>
            <div
                onClick={toggleModal}
                className={`w-[40px] cursor-pointer ${isOpen ? 'border-[#1EB469]' : ''} border-2   h-[40px] rounded-full overflow-hidden ${!ImageData ? "bg-[#1EB469]" : ""}`}>
                {!getBankerData?.data?.profile?.profile_image  ? <h2 className=' text-white text-center'>{convertToInitials(data?.user?.name)}</h2> : <img className='w-full imgClassHeight' src={`${apiHost}${getBankerData?.data?.profile?.profile_image}`} alt='' />}
            </div>
            {isOpen && (
                <div className='md:w-[400px] w-[18rem] absolute right-0 top-14 rounded-lg border bg-white'>
                    <div className='flex p-6 '>
                        <div className={`w-[65px] relative h-[65px] flex justify-center items-center overflow-hidden rounded-full ${!getBankerData?.data?.profile?.profile_image ? "bg-[#1EB469]" : ""}`}>
                        {!getBankerData?.data?.profile?.profile_image ? <h2 className='h2-text text-white text-center'>{convertToInitials(data?.user?.name)}</h2> : <img className='w-full imgClassHeight' src={`${apiHost}${getBankerData?.data?.profile?.profile_image}`} alt='' />}
                        </div>
                        <div className='ml-2 text-start mt-3'>
                            <h6 className='h6-text'>{data?.user?.name || "Not Available"}</h6>
                            <p className='body-text'>{data?.user?.email || "Not Available"}</p>
                        </div>
                    </div>

                    <div onClick={() => navigate("/dashboard/company/my-profile")} className='flex items-center px-6 py-4 cursor-pointer'>
                        <div>
                            <ProfileIcon1 />
                        </div>
                        <div>
                            <p className='body-text ml-2'>My Profile</p>
                        </div>
                    </div>
                    <div className='flex items-center px-6 py-4 cursor-pointer' onClick={()=>navigate("/dashboard/company/reset-password")}>
                        <div>
                            <ChangePasswordIcon />
                        </div>
                        <div onClick={()=>navigate("/dashboard/company/reset-password")}>
                            <p className='body-text ml-2'>Change Password</p>
                        </div>
                    </div>

                    {/* <div className='flex items-center px-6 py-4 cursor-pointer'>
                        <div>
                            <HelpAndSupportIcon />
                        </div>
                        <div>
                            <p className='body-text ml-2'>Help & Support</p>
                        </div>
                    </div> */}

                    <div className='flex items-center px-6 py-4 cursor-pointer' onClick={() => setIsFeedbackOpen(true)}>
                        <div>
                            <FeedBackIcon />
                        </div>
                        <div>
                            <p className='body-text ml-2'>Feedback</p>
                        </div>
                    </div>

                    <div className=" bg-[#E0E1E6] border mt-2 mx-6"></div>

                    <div onClick={onClick} className='flex cursor-pointer items-center px-6 py-6'>
                        <div>
                            <LogOutIcon1 />
                        </div>
                        <div>
                            <p className='body-text ml-2'>Log Out</p>
                        </div>
                    </div>
                </div>
            )}
            {isFeedbackOpen && <FeedbackModal type="Company" onClose={() => setIsFeedbackOpen(!isFeedbackOpen)} />}
        </div>
    );
};

export default CompanyProfileOption;