import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import usePost from '../../../../Hook/usePost';
import { ArrayOfTime, ValidationForMeeting, convertDate, convertDateToInputFormat, convertDateToInputFormatMeeting, getFutureTimes } from '../../../../Hook/validation';
import { ToastMessage } from '../../../../common/Config';
import Button from '../../../../common/Button';
import { AddClientIcon, CrossIcon1, DeleteGreenButton, DeleteIcon, DiscriptionIcon, DownloadGreemButton, GroupIcon, IButtonIcon, JobListingCompPencil, LinkIcon, LoctionIcon1, TimeIcon } from '../../../../common/Icons';
import InputField from '../../../../common/InputField';
import Checkbox from '../../../../common/Checkbox';
import SelectTagComponent from '../../../../common/SelectTagComponent';
import useGetData from '../../../../Hook/useGetData';
import Tooltip from './Tooltip';

const CompanyAddEvent = ({hideDropDown, isOpen, onClose, onSave,type,eventInfo,banker,onCloseView }) => {
  const token=JSON.parse(sessionStorage.getItem("userToken")) || "";
  const { data: CompanyEventData,loading:CompanyEventLoading, 
    error: CompanyEventError, postData: postCompanyEventData } = usePost(`hiring/event-company`);
    const { data: CompanyEventUpdateData,loading:EventUpdateLoading, 
      error: CompanyEventUpdateError, postData: postCompanyEventUpdateData } = usePost(`hiring/update-event-company`);
      const { data: DeleteEventData,loading:DeleteEventLoading, 
        error: DeleteEventError, postData: postDeleteEventData } = usePost(`hiring/delete-event`);
    const { data: getBankerList } = useGetData(`hiring/get-bankers`,token);
    const [userName,setUserName]=useState("")
    const [formState, setFormState] = useState({
    title: '',
    is_virtual: true,
    preferred_date: '',
    banker:banker || '',
    email: '',
    preferred_start_time: '',
    preferred_end_time: '',
    description: '',
    priority: 1,
  });
  const [selectedPriority, setSelectedPriority] = useState(null);
  const [isInPerson, setIsInPerson] = useState(false);
  const [isRepeat, setIsRepeat] = useState(false);
  const [selectedDays, setSelectedDays] = useState([]);
  const [errors, setErrors] = useState({});

  const days = [
    { label: 'M', name: 'Monday' },
    { label: 'T', name: 'Tuesday' },
    { label: 'W', name: 'Wednesday' },
    { label: 'T', name: 'Thursday' },
    { label: 'F', name: 'Friday' },
    { label: 'S', name: 'Saturday' },
    { label: 'S', name: 'Sunday' },
  ];


  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (errors[name]) ValidationForMeeting(formState, setErrors);
  };

  const handleSelectChange = (name, val) => {
    if(name==='banker_user'){
      setUserName(val.name)
      setFormState((prevState) => ({
        ...prevState,
        [name]: val.id,
      }));
    }else{
      setFormState((prevState) => ({
        ...prevState,
        [name]: val.name,
      }));
    }
  
    ValidationForMeeting({ ...formState, [name]: val.name }, setErrors);
  };

  const handleCheckboxChange = (type) => {
    setIsInPerson(type === 'in-person');
    setFormState((prevState) => ({
      ...prevState,
      is_virtual: type === 'virtual',
    }));
  };

  const handleClick = (dayName) => {
    setSelectedDays((prev) =>
      prev.includes(dayName)
        ? prev.filter((d) => d !== dayName)
        : [...prev, dayName]
    );
    setFormState((prevState) => ({ ...prevState, repeat_days: selectedDays }));
  };

  const handlePriorityClick = (priority) => {
    setSelectedPriority(priority);
    setFormState((prevState) => ({
      ...prevState,
      priority,
    }));
  };

  const handleSubmit = () => {

    if (ValidationForMeeting(formState, setErrors)) {
      if(type=='companyProjectDetail'){
        const data = {
          banker_id: formState?.banker_user || 1,
          title: formState?.title || "",
          consultancy_mode: formState?.is_virtual===false ? 2 :1,
          location:formState?.email || "",
          event_date: formState?.preferred_date || "",
          start_time:formState?.preferred_start_time || "",
          "end_time": formState?.preferred_end_time=="24:00" ? "00:00" :formState?.preferred_end_time,
          description: formState?.description || "",
          priority: formState?.priority || 1,
          is_repeat: isRepeat,
          repeat_interval:formState?.repeatEvery || 1,
          "meeting_link":formState?.link,
          repeat_start_date:formState?.startRepeatDate || "",
          repeat_end_date:formState?.endRepeatDate || "",
          repeat_unit:formState?.repeate_unit?.toLowerCase() || "",
      
      
      }
      postCompanyEventData(data,token)
      }
      if(type=='addCalendarCompany' && formState?.edit !==true){
        const data = {
          "banker_id":formState?.banker_user || 1,
          "title": formState?.title || "",
          "consultancy_mode": formState?.is_virtual===false ? 2 :1,
          "location": formState?.email,
          "event_date":formState?.preferred_date,
          "start_time":formState?.preferred_start_time,
          "end_time": formState?.preferred_end_time=="24:00" ? "00:00" :formState?.preferred_end_time,
          "description":formState?.description,
          "priority":formState?.priority,
          "meeting_link":formState?.link,
          "is_repeat": isRepeat || false,
          "repeat_interval":parseInt(formState?.repeatEvery) || 1,
          "repeat_start_date":formState?.startRepeatDate,
          "repeat_end_date":formState?.endRepeatDate,
          "repeat_unit":formState?.repeate_unit?.toLowerCase(),
          "weekly_repeat_days":selectedDays ||formState?.repeat_days || [] ,
          unit:formState?.repeatType?.toLowerCase(),
      
      
      
      }
      postCompanyEventData(data,token)
      }
      if(formState?.edit===true){
        const data = {
          repeat_unique_code:formState?.repeat_unique_code,
          "event_id": formState?.id,
          "banker": formState?.banker || 1,
          "title": formState?.title || "",
          "consultancy_mode": formState?.is_virtual===false ? 2 :1,
          "location": formState?.email,
          "event_date":formState?.preferred_date,
          "start_time":formState?.preferred_start_time,
          "end_time": formState?.preferred_end_time=="24:00" ? "00:00" :formState?.preferred_end_time,
          "description":formState?.description,
          "priority":formState?.priority,
          "meeting_link":formState?.link,
      
          "is_repeat": isRepeat || false,
          "repeat_interval":parseInt(formState?.repeatEvery || formState?.repeat_interval),
          "repeat_start_date":formState?.startRepeatDate || formState?.repeat_start_date,
          "repeat_end_date":formState?.endRepeatDate || formState?.repeat_end_date,
          "repeat_unit":formState?.repeate_unit?.toLowerCase() || formState?.repeat_unit,
          "weekly_repeat_days":selectedDays ||formState?.repeat_days || [] ,
          unit:formState?.repeatType?.toLowerCase(),
      }
      postCompanyEventUpdateData(data,token) 
      }
      // ToastMessage('success', 'Meeting Scheduled Successfully');
      // onSave(formState);
      // onClose();
    }
  };
  const RepeatList=[{ id: 1, name: 'Day' }, { id: 2, name: 'Week' }, { id: 3, name: 'Month' }, { id: 4, name: 'Year' }]
  const handleDelete=()=>{
  const obj={
    event_id:formState?.id
  }
  postDeleteEventData(obj,token)
  }
useEffect(()=>{
  if(CompanyEventData){
    ToastMessage('success', `Your meeting with Mr./Ms. ${userName} has been scheduled on ${convertDate(formState?.preferred_date)} at ${formState?.preferred_start_time} hours.`);
    onClose()
  }
  if(CompanyEventError){
    ToastMessage('error', 'Meeting Scheduled Failed');
  }
},[CompanyEventData,CompanyEventError])

// useEffect(()=>{
//   if(DeleteEventData){
//     ToastMessage('success', 'Meeting deleted successfully.');
//     onClose()
//   }
//   if(CompanyEventError){
//     ToastMessage('error', 'Meeting Scheduled Failed');
//   }
// },[CompanyEventData,CompanyEventError])

useEffect(()=>{
  if(DeleteEventData){
    ToastMessage('success', 'Meeting Deleted Successfully');
    onClose()
    onCloseView()
  }
  if(DeleteEventError){
    ToastMessage('error', 'Meeting Deleted Failed');
  }
},[DeleteEventData,DeleteEventError])
useEffect(()=>{

  const obj={
    ...eventInfo,
    email:eventInfo?.location,
    consultancy_mode:eventInfo?.consultancy_mode,
    preferred_date:eventInfo?.event_date,
    repeatEvery:eventInfo?.repeat_interval,
    preferred_start_time:eventInfo?.start_time,
    preferred_end_time:eventInfo?.end_time,
    banker:banker || eventInfo?.banker,
    banker_user:banker || eventInfo?.banker,
    link:eventInfo?.meeting_link || "",
    repeate_unit:eventInfo?.repeat_unit,
  }
  setIsRepeat(eventInfo?.is_repeat)
  setIsInPerson(eventInfo?.consultancy_mode==2?true:false)
  setSelectedDays(eventInfo?.weekly_repeat_days || [])
  setSelectedPriority(eventInfo?.priority)
  setFormState(obj)
},[eventInfo,banker])
useEffect(()=>{
  if(type=='companyProjectDetail'){
    setFormState((prevState) => ({ ...prevState, banker: banker}));
  }
},[banker])
useEffect(()=>{
  let userName=getBankerList?.data?.find((item)=>item?.id==formState?.banker)?.name;
  if(CompanyEventUpdateData){
    ToastMessage('success', `Your meeting with Mr./Ms. ${userName} has been scheduled on ${convertDate(formState?.preferred_date)} at ${formState?.preferred_start_time} hours.`);
    setFormState({
      title: '',
      is_virtual: true,
      preferred_date: '',
      banker:'',
      email: '',
      preferred_start_time: '',
      preferred_end_time: '',
      description: '',
      priority: 1,
    })
    setIsRepeat(false)
    setSelectedPriority(1)
    setIsInPerson(false)
    onClose()
    onCloseView()
  }
  if(CompanyEventUpdateError){
    ToastMessage('error', 'Meeting Scheduled Failed');
  }
},[CompanyEventUpdateData,CompanyEventUpdateError])

const Id=banker || formState?.banker;
const User=getBankerList?.data?.filter((item)=>item?.id===Id )[0]?.name;

  if (!isOpen) return null;

  return (
    
    <div className="fixed top-0 right-0 left-0 z-[51] flex justify-center items-center w-full h-full bg-[#1E1F24] bg-opacity-50 backdrop-blur-sm">
    <div className="relative p-4 w-[90%] lg:w-[43.5rem] max-h-[100%] bg-white rounded-lg shadow">
      <div className="sticky top-0 right-0 left-0 flex items-center  justify-between  border-b rounded-t">
        <h5 className="h5-text mb-3">Schedule a meeting</h5>
            <Button
              type="button"
              onClick={()=>{
                setIsRepeat(false)
                setSelectedPriority(1)
                setIsInPerson(false)
                onClose()
              }}
              // onClick={toggleModal}
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
            >
              <CrossIcon1 />
            </Button>
          </div>
          <div className="overflow-auto h-[26rem] md:p-4 space-y-5 custom-scrollbar scrollbar-hide">
            <div className="flex items-center ml-1 gap-6">
              <JobListingCompPencil />
              <InputField
                astrix={false}
                disabled={false}
                required={true}
                label="Add Title"
                type="text"
                id="addTitle"
                name="title"
                error={errors?.title}
                value={formState.title}
                onChange={handleInputChange}
              />
            </div>
            <div className="flex items-center gap-6">
              <AddClientIcon />
           <SelectTagComponent
           hideDropDown={hideDropDown ? true :false}
        defaultVal={User ? User: "Select Banker"}
        options={getBankerList?.data}
        disabled={type=='companyProjectDetail' || formState?.edit===true}
        name="banker_user"
        // value={formData.selectMonth}
        onChange={(val)=>handleSelectChange('banker_user',val)}
        error={errors.banker_user}
      />
            </div>
            <div className="ml-12 md:flex md:gap-[10rem]">
              <Checkbox
                type="radio"
                id="virtual"
                label="Virtual"
                checked={!isInPerson}
                onChange={() => handleCheckboxChange("virtual")}
              />

              <div className='mt-4 md:mt-0'>
              
              <Checkbox
                type="radio"
                id="in-person"
                label="In-person"
                checked={isInPerson}
                onChange={() => handleCheckboxChange("in-person")}
              />
              </div>
             
            </div>
            {isInPerson && (
              <div className="flex items-center gap-6">
                <LoctionIcon1 />
                <InputField
                  astrix={false}
                  disabled={false}
                  required={true}
                  label="Add Location"
                  type="text"
                  id="address"
                  name="email"
                  error={errors?.email}
                  value={formState.email}
                  onChange={handleInputChange}
                />
              </div>
            )}

{(!isInPerson) && (
              <div className="flex items-center gap-6">
                  <LinkIcon />
                <InputField
                  astrix={false}
                  disabled={false}
                  required={true}
                  label="Add Link"
                  type="text"
                  id="link"
                  name="link"
                  error={errors?.link}
                  value={formState.link}
                  onChange={handleInputChange}
                />
              </div>
            )}
            <div>
             
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex items-center justify-start gap-6">
                  <div>
                    <TimeIcon />
                  </div>
                  <div className='w-full'>
                    <InputField
                      name="preferred_date"
                      value={formState.preferred_date}
                      error={errors?.preferred_date}
                      type="date"
                      mindates={new Date().toISOString().split("T")[0]} 
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                {/* <div className="flex items-center gap-2 mt-2 justify-between">
                <div className="w-full pl-12 md:pl-0">
                  <SelectTagComponent
                    defaultVal={formState?.start_time || "select time"}
                    options={getFutureTimes(ArrayOfTime, formState.preferred_date)}
                    name="preferred_start_time"
                    value={formState.preferred_start_time}
                    onChange={(val) =>
                      handleSelectChange('preferred_start_time', val)
                    }
                    error={errors?.preferred_start_time}
                  />
                </div>
                <div className='my-3 md:my-0'>
                    <p>to</p>
                  </div>
                  <div className="w-[85%] md:pl-0">
                  <SelectTagComponent
                    defaultVal={formState?.end_time || "select time"}
                    options={getFutureTimes(ArrayOfTime, formState.preferred_date)}
                    name="preferred_end_time"
                    value={formState.preferred_end_time}
                    onChange={(val) =>
                      handleSelectChange('preferred_end_time', val)
                    }
                    error={errors?.preferred_end_time}
                  />
                  </div>
                </div> */}
                                  <div className="lg:flex w-full items-center gap-3 justify-between">
    <div className="w-full lg:pl-0 pl-12">
        {/* <label for="start-time" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Start time</label> */}
        <div className="relative">
            <div className="absolute inset-y-0 end-0 top-0 flex items-center pe-3.5 pointer-events-none">
                <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                    <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z" clip-rule="evenodd"/>
                </svg>
            </div>
            <InputField
            label='Start Time'
            name="preferred_start_time"
                    value={formState.preferred_start_time}
                    onChange={handleInputChange}
                    error={errors?.preferred_start_time}
            type="time" id="start-time"
            className="bg-gray-50 border leading-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
            min="09:00"
            max="18:00"
            required 
               />
        </div>
    </div>
    <div className="w-full lg:pl-0 pl-12">
        {/* <label for="end-time" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">End time</label> */}
        <div className="relative">
            <div className="absolute inset-y-0 end-0 top-0 flex items-center pe-3.5 pointer-events-none">
                <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                    <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z" clip-rule="evenodd"/>
                </svg>
            </div>
            <InputField
            label='End Time'
             type="time"
             name="preferred_end_time"
             value={formState.preferred_end_time}
             onChange={handleInputChange}
             error={errors?.preferred_end_time}
            id="end-time"
            className="bg-gray-50 border leading-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
             min="09:00"
             max="18:00"
            required />
        </div>
    </div>
</div>
              </div>
            </div>
<div className='pl-12'>
<Checkbox
              label="Repeat"
              type="checkbox"
              id="isRepeat"
              checked={isRepeat}
              onChange={() => setIsRepeat(!isRepeat)}
            />


{ isRepeat && <div>
  <div className='flex relative items-center gap-8 w-full'>
    <div className='flex  w-[30%]  items-cente'>
      <p className='s3-text'>Start</p>
    </div>
    <div className='w-[100%]  flex items-center'>
             <InputField
                      name="startRepeatDate"
                      value={formState?.startRepeatDate || formState.repeat_start_date}
                      // error={errors?.preferred_date}
                      mindates={new Date().toISOString().split("T")[0]} 
                      type="date"
                      onChange={handleInputChange}
                    />
    </div>
  </div>

  <div className='flex relative items-center my-6 gap-8 w-full'>
  <div className='flex  w-[30%]  items-cente'>
      <p className='s3-text'>Repeat every</p>
    </div>
    <div className='w-[100%] md:flex gap-4 items-center '>
             <div>
             <InputField
                      name="repeatEvery"
                      value={formState.repeatEvery}
                      // error={errors?.preferred_date}
                      type="text"
                      onChange={handleInputChange}
                      onkeyDown={(e) => {
                         // Allow navigation and control keys
    const allowedKeys = ["Backspace", "Tab", "ArrowLeft", "ArrowRight", "Delete"];
    
    // If the key is not a number and not allowed control keys, prevent the input
    if (!allowedKeys.includes(e.key) && (isNaN(e.key) || e.key === " ")) {
      e.preventDefault();
    }

    // If the length of the current input is 2 or more and the key is not a control key, prevent more input
    if (e.target.value.length >= 2 && !allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
                      }
                    }
                    />
             </div>

             <div className='w-full pt-2'>
             <SelectTagComponent
               defaultVal={formState?.repeat_unit || "Select Unit"}
               options={RepeatList}
               onChange={(val) =>
                handleSelectChange('repeate_unit', val)
              }
              />
             </div>
    </div>
  </div>

  {(formState?.repeate_unit=='Week' || formState?.repeate_unit==='week') && <div className='flex items-center md:ml-40 my-8 gap-2'>
      {days.map((day, index) => (
        <div
          key={index}
          onClick={() => handleClick(day.name)}
          className={`w-[30px] h-[30px]  md:w-[40px] md:h-[40px] flex items-center justify-center  rounded-full cursor-pointer ${
            selectedDays.includes(day.name) ? 'bg-green-500 text-white' : 'bg-white text-black'
          }`}
        >
          {day.label}
        </div>
      ))}
    </div>}

  <div className='flex relative items-center gap-8 w-full'>
    <div className='flex  w-[30%]  items-cente'>
      <p className='s3-text'>End</p>
    </div>
    <div className='w-[100%]  flex items-center'>
             <InputField
                      name="endRepeatDate"
                      value={formState?.endRepeatDate || formState.repeat_end_date}
                      // error={errors?.preferred_date}
                      mindates={new Date().toISOString().split("T")[0]} 
                      type="date"
                      onChange={handleInputChange}
                    />
    </div>
  </div>

 {(formState?.repeate_unit=='Week' || formState?.repeate_unit==='week')  && <p className='s3-text my-5 text-[#2C2C2C]'>
  Occurs every {selectedDays?.map((item)=>{
    return `${item}, `
  })}
  </p>}
</div> }
</div>           
            <div className="flex my-4 gap-6">
              <div className="mt-2">
                <DiscriptionIcon />
              </div>
              <textarea
                id="description"
                name="description"
                rows="4"
                className="mt-1 resize-none block w-full rounded-md border-gray-300 border p-4"
                placeholder="Add Description"
                value={formState.description}
                onChange={handleInputChange}
                error={errors?.description}
              />
            </div>
            <p className="text-[#ff0000]">{errors?.description}</p>

            
            <div className="flex  items-start md:items-center gap-6">
              <Tooltip content="please select priority" >
              <IButtonIcon />

              </Tooltip>
              
              <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                <Button
                  onClick={() => handlePriorityClick(1)}
                  className={`pl-3 pr-5 py-1 rounded-full bg-[#B6E4CB] flex items-center gap-3 ${
                    selectedPriority === 1 ? "border-2 border-black" : ""
                  }`}
                >
                  <div
                    className={`w-[20px] h-[20px] rounded-full bg-[#0AA350] ${
                      selectedPriority !== "low" ? "opacity-50" : ""
                    }`}
                  ></div>
                  Low
                </Button>
                <Button
                  onClick={() => handlePriorityClick(2)}
                  className={`pl-3 pr-5 py-1 rounded-full bg-[#FFF6BE] flex items-center gap-3 ${
                    selectedPriority === 2
                      ? "border-2 border-black"
                      : ""
                  }`}
                >
                  <div
                    className={`w-[20px] h-[20px] rounded-full bg-[#FDD33E] ${
                      selectedPriority !== 2 ? "opacity-50" : ""
                    }`}
                  ></div>
                  Medium
                </Button>
                <Button
                  onClick={() => handlePriorityClick(3)}
                  className={`pl-3 pr-5 py-1 rounded-full bg-[#FFD3B3] flex items-center gap-3 ${
                    selectedPriority === 3 ? "border-2 border-black" : ""
                  }`}
                >
                  <div
                    className={`w-[20px] h-[20px] rounded-full bg-[#FF6B00] ${
                      selectedPriority !== 3 ? "opacity-50" : ""
                    }`}
                  ></div>
                  High
                </Button>
                <Button
                  onClick={() => handlePriorityClick(4)}
                  className={`pl-3 pr-5 py-1 rounded-full bg-[#FFC6C6] flex items-center gap-3 ${
                    selectedPriority === 4
                      ? "border-2 border-black"
                      : ""
                  }`}
                >
                  <div
                    className={`w-[20px] h-[20px] rounded-full bg-[#FD3E3E] ${
                      selectedPriority !== 4 ? "opacity-50" : ""
                    }`} 
                  ></div>
                  Critical
                </Button>
              </div>
            </div>
            <div className="h-[3rem]"></div>
          </div>
          <div className="absolute bottom-0 left-0 right-0 bg-white flex justify-between items-center py-2 px-6 border-t border-gray-200 rounded-b">
         {formState?.edit===true ? <Button
              onClick={handleDelete}
              className="  redfolicbg  flex items-center gap-1 rounded-lg px-6 py-2 text-white"
            >
              <DeleteGreenButton/>
              Delete
            </Button> :<div></div>}
            <Button
              onClick={handleSubmit}
              className="greenbg rounded-lg px-10 py-2 text-white"
            >
              Save
            </Button>
          </div>
        </div>
      </div>
  
  );
};

export default CompanyAddEvent;
