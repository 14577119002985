import React, { useEffect, useState } from "react";
import { MarginForProfileCoreExpertise, PrfileCardDashtyle, PrfileCardTitleStyle, PrfileCardValueStyle, profileCardStyles } from "./StyleCommon";
import Button from "./Button";
import RoundedBox from "./RoundedBox";
import { SaveIconImg, SavedIcon } from "./Icons";
import TitleValueComp from "./TitleValueComp";
import { useNavigate, useParams } from "react-router-dom";
import ScheduledMeeting from "../modules/dashboard/company/job/ScheduledMeeting";
import { ToastMessage, apiHost } from "./Config";
import usePost from "../Hook/usePost";
import { convertDate, splitName } from "../Hook/validation";
import useGetData from "../Hook/useGetData";
 
const ProfileCard = ({
  sideCard,
  ExperinceDataUI,
  CoreUI,
  ScheduleBtn,
  ProfileDetails,
  shortlistBtn,
  hireBtn,
  SaveIconShow,
  saveIconUI,
  ScheduleOnClick,
  data,
  type,
  job_id,
  refetch,
  typeOf,
  path,
  fullName
}) => {
  const [shortlist , SetShortListed] = useState(false)
  const ShortListName = shortlist || data?.shortlist ? "Shortlisted" : "Shortlist"
  const token=JSON.parse(sessionStorage.getItem("userToken")) || "";
   const [saveIcon , SetSaveIcon] = useState(!false)
   const {id}=useParams()
   const { data: getDesignationData } = useGetData('master-data/designation/get', token);
   const { data: sortListData,loading:sortListLoading, error: sortListError, postData: postSortListData } = usePost(`job/save-sortlist`);
   const { data: unsortListData,loading:unsortListLoading, error: unsortListError, postData: unpostSortListData } = usePost(`job/unsave-sortlist`);
   const { data: saveJobData,loading:saveJobLoading, error: saveJobError, postData: postSaveJobData } = usePost(`job/company-banker-save`);
   const { data: bankerSaveData,loading:bankerSaveLoading, error: bankerSaveError, postData: postBankerSaveData } = usePost(`job/company-banker-save`);
   const { data: bankerAdvanceSaveData,loading:bankerAdvanceSaveLoading, error: bankerAdvanceSaveError, postData: postBankerAdvanceSaveData } = usePost(`job/advance-save-banker`);
   const { data: bankerUnSaveData, error: bankerUnSaveError, postData: postBankerUnSaveData } = usePost(`job/unsave-job-banker`);
   const { data: advanceUnSaveData, error: advanceUnSaveError, postData: postAdvanceUnSaveData } = usePost(`job/advance-unsave-banker`);
   const { data: dashboardUnSaveData, error: dashboardUnSaveError, postData: postDashboardUnSaveData } = usePost(`job/company-banker-unsave`);
  // const SaveIconName = 
  const navigate = useNavigate();




  const handleShortlist=(shrtlist)=>{
    // if (shrtlist || data?.shortlist) {
    //   ToastMessage("info", "Banker profile is already shortlisted");
    //   return;
    // }
    const obj={
    job_id: id,
    banker_id: data?.user?.id || data?.bkUser?.id
    }
    if(obj){
      if (shrtlist || data?.shortlist) {
        unpostSortListData(obj,token);
        return;
      }
      postSortListData(obj,token)
      SetShortListed(!shortlist)
    }
  }
  const handleSaveList=()=>{
    const obj={
    job_id: data?.id,
    banker_id: data?.user?.id || data?.bkUser?.id
    }
  

    
    if(obj){
      if(typeOf==='CompnayDashboard'){
        postBankerSaveData(obj,token)
      }
      if(typeOf=='AdvanceSearch'){
        postBankerAdvanceSaveData(obj,token)
      }
      if(typeOf==='SavedBanker'){
        postSaveJobData(obj,token)
      }
      if(typeOf==='SuggestedProfiles'){
        postSaveJobData(obj,token)
      }
      if(typeOf==='JobListingComp'){
        postSaveJobData(obj,token)
      }
 
      SetSaveIcon(!saveIcon)
    }
    
  }
  const handleUnSaveList=()=>{
   
      if(typeOf==='CompnayDashboard'){
        postDashboardUnSaveData({
          job_id: data?.id,
          banker_id: data?.user?.id || data?.bkUser?.id
          },token)
      }
      if(typeOf==='SavedBanker'){
        postDashboardUnSaveData({
          job_id: data?.id,
          banker_id: data?.user?.id || data?.bkUser?.id
          },token)
      }
      if(typeOf==='AdvanceSearch'){
        postAdvanceUnSaveData({
          job_id: data?.id,
          banker_id: data?.user?.id || data?.bkUser?.id 
        },token)
      }
    
      if(typeOf==='SuggestedProfiles'){
        postDashboardUnSaveData({
          job_id: data?.id,
          banker_id: data?.user?.id || data?.bkUser?.id 
        },token)
      }
      if(typeOf==='JobListingComp'){
        postDashboardUnSaveData({
          job_id: data?.id,
          banker_id: data?.user?.id || data?.bkUser?.id 
        },token)
      }
  }
  const handleJobBankerProfileNavigate=()=>{
    if(typeOf==='SuggestedProfiles'){
      navigate(`/dashboard/company/profile/${id}/${data?.id}`)
      sessionStorage.setItem('typeOf',typeOf)
    }
    if(typeOf==='CompnayDashboard'){
      navigate(`/dashboard/company/profile/${data?.bkUser?.id}`)
      sessionStorage.setItem('typeOf',typeOf)
    }
    if(typeOf==='SavedBanker'){
      navigate(`/dashboard/company/profile/${data?.id}`)
      sessionStorage.setItem('typeOf',typeOf)
    }
    if(typeOf==='AdvanceSearch'){
      navigate(`/dashboard/company/profile/${data?.id}`)
      sessionStorage.setItem('typeOf',typeOf)
    }
  }
  useEffect(()=>{
    if(sortListData){
      ToastMessage('success',"Profile Shortlisted successfully");
      if (refetch) refetch();
    }
    if(sortListError){
      ToastMessage('error',sortListError?.response?.data?.error || "Sort listed failed");
    }

  },[sortListData,sortListError])
  useEffect(()=>{
    if(unsortListData){
      ToastMessage('success',"Profile removed successfully");
      if (refetch) refetch();
    }
    if(unsortListError){
      ToastMessage('error',sortListError?.response?.data?.error || "Sort list remove failed");
    }

  },[unsortListData,unsortListError])
  useEffect(()=>{
    if(saveJobData || bankerSaveData){
      ToastMessage('success',"Profile Saved successfully");
      if (refetch) refetch();
    }
    if(saveJobError || bankerSaveError){
      ToastMessage('error',bankerSaveError?.response?.data?.message || saveJobError?.response?.data?.message);
    }

  },[saveJobData,bankerSaveData,saveJobError,bankerSaveError])
  useEffect(()=>{
    if(bankerAdvanceSaveData){
      ToastMessage('success',"Profile Saved successfully");
      if (refetch) refetch();
    }
    if(bankerAdvanceSaveError){
      ToastMessage('error',bankerAdvanceSaveError?.response?.data?.error || "Job Save failed");
    }
  },[bankerAdvanceSaveData,bankerAdvanceSaveError])
  useEffect(()=>{
    SetSaveIcon(true || data?.save_banker || false)
  },[data?.save_banker])
  useEffect(()=>{
    if(bankerUnSaveData){
      ToastMessage('success',"Profile UnSaved successfully");
      if (refetch) refetch();

    }
    if(bankerUnSaveError){
      ToastMessage('error',bankerUnSaveError?.response?.data?.error || "Job UnSave failed");
    }
  })
  useEffect(()=>{
    if(advanceUnSaveData){
      ToastMessage('success',"Profile UnSaved successfully");
      if (refetch) refetch();
    }
    if(advanceUnSaveError){
      ToastMessage('error',advanceUnSaveError?.response?.data?.error || "Job UnSave failed");
    }
  }
  , [advanceUnSaveData,advanceUnSaveError])
  useEffect(()=>{
    if(dashboardUnSaveData){
      ToastMessage('success',"Profile UnSaved successfully");
      if (refetch) refetch();
    }
    if(dashboardUnSaveError){
      ToastMessage('error',dashboardUnSaveError?.response?.data?.error || "Job UnSave failed");
    }
  },[dashboardUnSaveData, dashboardUnSaveError])
  const Designation=getDesignationData?.data?.filter((item)=>item.id==data?.designation)[0]?.name
  const handleNavigate=(id)=>{
    navigate(`/dashboard/company/profile`)
  }

  // const amount = 1000000; // 10 lakh
  const formattedAmount = new Intl.NumberFormat('en-IN').format(data?.payout);

  return (
    <div className={profileCardStyles.container}>
      <div className={profileCardStyles.header}>
        <div className={profileCardStyles.profileInfoContainer}>
          <div className={profileCardStyles.profileImage}>
            <img src={`${apiHost}/${data?.profile_image}`} alt="" className={profileCardStyles.profileImage} />
          </div>
          <div className={profileCardStyles.profileDetails} role="button" onClick={handleJobBankerProfileNavigate}>
            <h5 className={profileCardStyles.nameTitle}>{splitName(data?.bkUser?.name) || "Not available"}</h5>
            <p className={profileCardStyles.subTitle}>{data?.city?.city || "Not available"}</p>
          </div>
        </div>
        <div className={profileCardStyles.actionContainer}>
          {hireBtn && <Button disabled={data?.job_hire_status} onClick={()=>navigate(`/dashboard/hirenow/${job_id ?? data?.id}/${data?.bkUser?.id}`)} className={`${profileCardStyles.button} ${data?.job_hire_status ? 'bg-[gray]':'greenbg'}`}>{hireBtn}</Button>}
          {shortlistBtn && <Button  className={`${profileCardStyles.button} redfolicbg ${true && "fade"}`} onClick={() => handleShortlist(data?.shortlist)}>{ShortListName}</Button>}
          {/* {ScheduleBtn && <Button className={`px-6 py-2 text-white greenbg rounded-lg`}>{ScheduleBtn}</Button>} */}
          {ScheduleBtn && <ScheduledMeeting ScheduleBtn={ScheduleBtn} data={data}/>}

      { saveIconUI===true ?  SaveIconShow && (data?.is_saved !==true) ? <div role="button"><SaveIconImg onClick ={handleSaveList}/></div> :<div role="button"> <SavedIcon 
        onClick ={handleUnSaveList}
        /></div>  : ''}
        </div>
      </div>
     {
       ProfileDetails && <TitleValueComp data={ProfileDetails}/>
    }
      <div className={profileCardStyles.tabsContainer}>
          <div className={profileCardStyles.tab}>
            {data?.tenure?.name || data?.master_tenure?.name || "Not available"}
          </div>
          <div className={profileCardStyles.tab}>
            {data?.master_consultancy_mode==1 ? "Virtual":data?.master_consultancy_mode==2 ? "In Person":"Virtual,In Person"}
          </div>
          <div className={profileCardStyles.tab}>
            {/* Monthly Salary :{data?.payout || "Pending from admin"} */}
            {data?.payout ? "₹" : ''} { data?.payout ? formattedAmount : "Pending from admin"}{data?.payout ? '/month' : ''}

          </div>
      </div>
        <>
     {ExperinceDataUI=== false ? "" : <>    
     <div className={profileCardStyles.experienceTitle}>
          <h6 className="h6-text">Experience</h6>
        </div>
      <div className={sideCard === true ?profileCardStyles.experienceFlex:profileCardStyles.experienceGrid}>
      
            <div  className={profileCardStyles.experienceItem}>
              <div className={PrfileCardTitleStyle}><p className="body-text">Last company</p></div>
              <div className={PrfileCardDashtyle}>-</div>
              <div className={PrfileCardValueStyle}>{data?.bank_name || "Not available"}</div>
            </div>
            <div  className={profileCardStyles.experienceItem}>
              <div className={PrfileCardTitleStyle}><p className="body-text">Date of retirement</p></div>
              <div className={PrfileCardDashtyle}>-</div>
              <div className={PrfileCardValueStyle}>{data?.retirement_date ? convertDate(data?.retirement_date) : "Not available"}</div>
            </div>
            <div  className={profileCardStyles.experienceItem}>
              <div className={PrfileCardTitleStyle}><p className="body-text">Total experience</p></div>
              <div className={PrfileCardDashtyle}>-</div>
              <div className={PrfileCardValueStyle}>{data?.banking_experience || 0} Years</div>
            </div>
            <div  className={profileCardStyles.experienceItem}>
              <div className={PrfileCardTitleStyle}><p className="body-text">Last Designation</p></div>
              <div className={PrfileCardDashtyle}>-</div>
              <div className={PrfileCardValueStyle}>{data?.designation==7 ? data?.custom_designation: Designation || data?.designation?.name || "Not available"}</div>
            </div>
            
       
      </div>
       </>}
        </>

        <>
        {
          CoreUI ===false ? "" : <>
          {data?.core_expertise && (
        <div className={profileCardStyles.expertiseTitle}>
          <h6 className="h6-text">Core expertise</h6>
        </div>
      )}
      <div className={MarginForProfileCoreExpertise}>
        {data?.core_expertise && <RoundedBox data = {data?.core_expertise?.map((item)=>item?.name)} cross={false}/>}
      </div> 
      </>
        }
        </>
      
    </div>
  );
};
 
export default ProfileCard;