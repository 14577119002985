import React, { useEffect, useState } from "react";
import Navbar from "../../../../common/Navbar";
import Checkbox from "../../../../common/Checkbox";
import SelectTagComponent from "../../../../common/SelectTagComponent";
import ProfileCard from "../../../../common/ProfileCard";

import Dropdown from "../../../../common/Dropdown";
import RoundedBox from "../../../../common/RoundedBox";
import { FilterIcon} from "../../../../common/Icons";
import usePost from "../../../../Hook/usePost";
import DataNotFound from "../../../../common/DataNotFound";
import { useLocation } from "react-router-dom";
import useGetData from "../../../../Hook/useGetData";
import { ToastMessage } from "../../../../common/Config";
import Pagination from "../../../../common/Pagination";
import SideNavbar from "../../../../common/SideNavbar";
import LoaderAPI from "../../../../common/LoaderAPI";

const DashboardFilter = () => {
  const token=JSON.parse(sessionStorage.getItem("userToken")) || "";
  const locationData=useLocation()
  const PageOptions = [
    {
      id: 1,
      name: 5,
    },
    {
      id: 2,
      name: 10,
    },
    {
      id: 3,
      name: 20,
    },
    {
      id: 4,
      name: 30,
    },
    {
      id: 5,
      name: 40,
    },
    {
      id: 6,
      name: 50,
    },
  ];
  const { data: getActiveData, loading: loadingActive, error: errorActive } = useGetData('master-data/active/get', token);
  const { data: bankerSearchData,loading:bankerSearchLoading, 
    error: bankerSearchError, postData: postBankerSearchData } = usePost(`job/banker-search`);
    const { data: designation, loading: loadingDesignation, error: errorDesignation,refetch:fetchDesignationData } = useGetData('master-data/designation/get', token);
    const { data: keyServicesData, loading: loadingKeyServices, error: errorKeyServices,refetch:fetchKeyServicesData } = useGetData('master-data/service/index', token);
    const { data: getStateData, loading: loadingState, error: errorState } = useGetData('master-data/state/get',"token");
  const [stateId,setStateId]=useState('')
  const [cityId,setCityId]=useState('')

      const { data: getCityData, loading: loadingCity, error: errorCity } = useGetData(`master-data/city/${stateId}`,"token")
      const { data: getEducationData } = useGetData(`master-data/education/index`,"token")
      const { data: SavedSearchData,loading:saveSearchLoading, 
        error: saveSearchError, postData: postSaveSearchData } = usePost(`job/save-search`);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptions1, setSelectedOptions1] = useState([]);
  const [selectedOptions2, setSelectedOptions2] = useState(getCityData?.data?.filter((item)=>item.id===locationData?.state?.formData?.city)[0]?.city || []);
  const [selectedOptions3, setSelectedOptions3] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [ActiveFilter , setActiveFilter] = useState(false)
  const [formData,setFormData]=useState({
    tenure: [],
    consultancy_mode: [],
    designation:[],
    service_area: [],
    banker_experience_min_yrs: 0,
    banker_experience_max_yrs: 0,
    preferred_location: [],
    education_level:[],
    is_saved: false,
    mandatory_fields: [],
    active:1
  })
  const [local,setLocal]=useState(false)
  const [data,setData]=useState([1,2]);
  const handleAvailabilityHireChange = (e) => {
    const { value } = e.target;
    setFormData((prevState) => {
      const tenure = Array.isArray(prevState.tenure) ? prevState.tenure : [];
      const consultancy_mode = Array.isArray(prevState.consultancy_mode) ? prevState.consultancy_mode : [];

      switch (value) {
        case 'Short term':
          return {
            ...prevState,
            tenure: tenure.includes(1) ? tenure.filter(item => item !== 1) : [...tenure, 1],
          };
        case 'Medium term':
          return {
            ...prevState,
            tenure: tenure.includes(2) ? tenure.filter(item => item !== 2) : [...tenure, 2],
          };
        case 'Long term':
          return {
            ...prevState,
            tenure: tenure.includes(3) ? tenure.filter(item => item !== 3) : [...tenure, 3],
          };
        case 'Virtual':
          return {
            ...prevState,
            consultancy_mode: consultancy_mode.includes(1) ? consultancy_mode.filter(item => item !== 1) : [...consultancy_mode, 1],
          };
        case 'In Person':
          return {
            ...prevState,
            consultancy_mode: consultancy_mode.includes(2) ? consultancy_mode.filter(item => item !== 2) : [...consultancy_mode, 2],
          };
        case 'Both':
          return {
            ...prevState,
            consultancy_mode: consultancy_mode.includes(3) ? consultancy_mode.filter(item => item !== 3) : [...consultancy_mode, 3],
          };
        default:
          return prevState;
      }
    });
  };

const handlePageChange = (pageNumber) => {
  setCurrentPage(pageNumber);
};

const handleChange = (options) => {
  setItemsPerPage(options?.name);
  setCurrentPage(1);
};

  const [filterAvailHire, setFilterAvailHire] = useState([
    {
      "id": 1,
      "name": "Short term",
      "isChecked": false
    },
    {
      "id": 2,
      "name": "Medium term",
      "isChecked": false
    },
    {
      "id": 3,
      "name": "Long term",
      "isChecked": false
    }
  ]);

  const [modeOfConsult, setModeOfConsult] = useState([
    {
      "id": 1,
      "name": "Virtual",
      "isChecked": false
    },
    {
      "id": 2,
      "name": "In Person",
      "isChecked": false
    },
    {
      "id": 3,
      "name": "Both",
      "isChecked": false
    }
  ]);
  
  const LevelOfEducation =getEducationData?.data?.map((item)=>item?.name) || [];
  const SearchData = designation?.data?.map((item)=>item?.name) || [];
  const keyServices=keyServicesData?.data?.map((item)=>item?.name) || []
 
  const handleOptionSelect = (option) => {
    if (!selectedOptions.includes(option)) {
      const id=designation?.data?.filter(item => item?.name === option)?.map((item)=>item?.id)[0];
      setFormData({...formData, designation: [...formData?.designation,id]});
      setSelectedOptions([...selectedOptions, option]);
    }
  };
  const handleOptionSelect1 = (option) => {
    if (!selectedOptions1.includes(option)) {
      const id=keyServicesData?.data?.filter(item => item?.name === option)?.map((item)=>item?.id)[0];
      setFormData({...formData, service_area: [...formData?.service_area,id]});
      setSelectedOptions1([...selectedOptions1, option]);
    }
  };

  const handleOptionSelect2 = (option) => {
    if (!selectedOptions2.includes(option)) {
      const id=getCityData?.data?.filter(item => item?.city === option)?.map((item)=>item?.id)[0];
      setFormData({...formData, preferred_location: [...formData?.preferred_location,id]});
      setSelectedOptions2([...selectedOptions2, option]);
    }
 
  };
  const handleOptionSelect3 = (option) => {
    if (!selectedOptions3.includes(option)) {
      const id=getEducationData?.data?.filter(item => item?.name === option)?.map((item)=>item?.id)[0];
      setFormData({...formData, education_level: [...formData?.education_level,id]});
      setSelectedOptions3([...selectedOptions3, option]);
    }

  };
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentItems = data?.slice(startIndex, startIndex + itemsPerPage);
  const totalPages = Math.ceil(data?.length / itemsPerPage) || 1 ;

  const handleOptionRemove = (option) => {
    const id=designation?.data?.filter(item => item?.name === option)?.map((item)=>item?.id)[0];
    setFormData({...formData, designation: formData?.designation?.filter(item => item !== id)})
    setSelectedOptions(selectedOptions.filter(item => item !== option));
  };
  const handleOptionRemove1 = (option) => {
    const id=keyServicesData?.data?.filter(item => item?.name === option)?.map((item)=>item?.id)[0];
    setFormData({...formData, service_area: formData?.service_area?.filter(item => item !== id)})
    setSelectedOptions1(selectedOptions1.filter(item => item !== option));
  };
  const handleOptionRemove2 = (option) => {
    const id=getCityData?.data?.filter(item => item?.city === option)?.map((item)=>item?.id)[0];
    setFormData({...formData, preferred_location: formData?.preferred_location?.filter(item => item !== id)})
    setSelectedOptions2(selectedOptions2.filter(item => item !== option));
  };
  const handleOptionRemove3 = (option) => {
    const id=getEducationData?.data?.filter(item => item?.name === option)?.map((item)=>item?.id)[0];
    setFormData({...formData, education_level: formData?.education_level?.filter(item => item !== id)})
    setSelectedOptions3(selectedOptions3.filter(item => item !== option));
  };
  const saveSearchData=JSON.parse(sessionStorage.getItem("bankerFilterData"))
  let tenure=saveSearchData?.tenure[saveSearchData?.tenure?.length-1];
  let EducationId=saveSearchData?.education_level[saveSearchData?.education_level?.length-1];
  let EducationText= getEducationData?.data?.filter(item => item?.id === EducationId)?.map((item)=>item?.name)[0];
  let servicesId=saveSearchData?.service_area[saveSearchData?.service_area?.length-1];
  let servicesText= keyServicesData?.data?.filter(item => item?.id === servicesId)?.map((item)=>item?.name)[0];

  const handleSaveSearch=()=>{
   
    if(bankerSearchData?.recent_searches){
      postSaveSearchData({},token)
    
    }


  }
  const handleClearAll=()=>{
    setSelectedOptions([])
    setSelectedOptions1([])
    setSelectedOptions2([])
    setSelectedOptions3([])
    let newFormData = {...formData};
    newFormData["tenure"] = [];
    newFormData["consultancy_mode"] = [];
    newFormData["designation"] = [];
    newFormData["service_area"] = [];
    newFormData["preferred_location"] = [];
    newFormData["education_level"] = [];
    newFormData["mandatory_fields"] = [];
    newFormData["is_saved"] = false;
    setFormData(newFormData);

    setData(locationData?.state?.bankerSearchData?.length ? locationData.state.bankerSearchData : (bankerSearchData?.banker_profiles || []));

 
  }
 
  
  const handleMandatory = (e,field) => {
    const { checked, value } = e.target;
    setFormData(prevState => {
      const updatedMandatoryFields = checked
        ? [...prevState.mandatory_fields, field]  // Add value if checked
        : prevState.mandatory_fields.filter(item => item !== field);  // Remove value if unchecked
      return { ...prevState, mandatory_fields: updatedMandatoryFields };
    });
  };
  const handleSelectTimePeriod=(val)=>{
    const obj={
      active:val?.id
    };
    setFormData(prevState => ({ ...prevState, active: obj.active }));
    // postActiveSearchData(obj,token)
  }
  useEffect(()=>{
 
    const newObject={
      tenure: [...new Set(formData?.tenure)] || formData?.tenure || 1,
      consultancy_mode: [...new Set(formData?.consultancy_mode)] || formData?.consultancy_mode || 1,
      designation:formData?.designation || [],
      service_area:formData?.service_area || [],
      preferred_location:formData?.preferred_location || [],
      education_level: formData?.education_level || [],
      is_saved: false,
      mandatory_fields:formData?.mandatory_fields || [],
      active: formData?.active || 5,
    
  }
  if(local){
    postBankerSearchData(newObject,token)
    sessionStorage.setItem("bankerFilterData",JSON.stringify(newObject))
  }
   
  },[formData,selectedOptions,selectedOptions1,selectedOptions2,selectedOptions3])
  
  useEffect(()=>{
if(bankerSearchData?.banker_profiles?.length>0){
  setData(bankerSearchData?.banker_profiles || [])
  setLocal(true)
}
if(bankerSearchData?.banker_profiles?.length===0 ){
  setData([])
}
  },[bankerSearchData,bankerSearchData?.banker_profiles?.length,bankerSearchError,bankerSearchLoading])

useEffect(()=>{
  if(locationData?.state?.formData){
    setFormData({...locationData?.state?.formData,preferred_location:locationData?.state?.formData?.city !=='' ?[locationData?.state?.formData?.city] :[]})
    setSelectedOptions(locationData?.state?.selectedOptions)
    setSelectedOptions1(locationData?.state?.selectedOptions1)
   if(locationData?.state?.City){

 setSelectedOptions2([locationData?.state?.City])
   }
   
    setSelectedOptions3(locationData?.state?.selectedOptions3)
    setTimeout(()=>{
      postBankerSearchData({...locationData?.state?.formData,preferred_location:locationData?.state?.formData?.city ?[locationData?.state?.formData?.city] :[]},token)
    },3000)
  
  }
},[locationData?.state?.formData])

useEffect(() => {
let newFilterAvailHire = filterAvailHire;
let newConsultModes = modeOfConsult;

for(let i=0;i<newFilterAvailHire.length;i++){
  if(formData?.tenure?.includes(newFilterAvailHire[i]["id"])) {
    newFilterAvailHire[i]["isChecked"] = true;
  }
  else {
    newFilterAvailHire[i]["isChecked"] = false;
  }
}
for(let i=0;i<newConsultModes.length;i++){
  if(formData?.consultancy_mode?.includes(newConsultModes[i]["id"])) {
    newConsultModes[i]["isChecked"] = true;
  }
  else {
    newConsultModes[i]["isChecked"] = false;
  }
}

setFilterAvailHire(newFilterAvailHire);
setModeOfConsult(newConsultModes);
}, [formData]);
useEffect(()=>{
  if(SavedSearchData){
    ToastMessage('success',"Saved Search Successfully")
  }
  if(saveSearchError){
    ToastMessage('error',"Something went wrong")
  }
},[SavedSearchData,saveSearchError])
const fetchData=()=>{
  const newObject={
    tenure: [...new Set(formData?.tenure)] || formData?.tenure || 1,
    consultancy_mode: [...new Set(formData?.consultancy_mode)] || formData?.consultancy_mode || 1,
    designation:formData?.designation || [],
    service_area:formData?.service_area || [],
    preferred_location:formData?.preferred_location || [],
    education_level: formData?.education_level || [],
    is_saved: false,
    mandatory_fields:formData?.mandatory_fields || [],
    active: formData?.active || 5,
  
}
  postBankerSearchData(newObject,token)
}
let state=getStateData?.data?.filter((item)=>item.id===stateId)[0]?.name || "";
let city=getCityData?.data?.filter((item)=>item.id===formData?.preferred_location[0] || cityId)[0]?.city || "";
const Tenure=()=>{
  let Text=""
  if(formData?.tenure?.length>0){
    if(formData?.tenure.includes(1)){
     Text+="Short Term, "
    }
    if(formData?.tenure.includes(2)){
      Text+="Medium Term, "
    }
    if(formData?.tenure.includes(3)){
      Text+="Long Term, "
    }
    Text=Text.slice(0,-2)
    }
  return Text
  
}
const ConsultancyMode=()=>{
  let Text=""
  if(formData?.consultancy_mode?.length>0){
    if(formData?.consultancy_mode.includes(1)){
     Text+="Virtual, "
    }
    if(formData?.consultancy_mode.includes(2)){
      Text+="In Person, "
    }
    if(formData?.consultancy_mode.includes(3)){
      Text+="Both, "
    }
    Text=Text.slice(0,-2)
    }
  return Text
}
const Designation=()=>{
  let Text=""
  if(formData?.designation?.length>0){
    designation?.data?.map((item)=>{
      if(formData?.designation.includes(item.id))
      Text+=item.name+", "
    })
    Text=Text.slice(0,-2)
    }
  return Text
}
const EducationLevel=()=>{
  let Text=""
  if(formData?.education_level?.length>0){
    getEducationData?.data?.map((item)=>{
      if(formData?.education_level.includes(item.id))
      Text+=item.name+", "
    })
    Text=Text.slice(0,-2)
    }
  return Text
}
const KeyServices=()=>{
  let Text=""
  if(formData?.service_area?.length>0){
    keyServicesData?.data?.map((item)=>{
      if(formData?.service_area.includes(item.id))
      Text+=item.name+", "
    })
    Text=Text.slice(0,-2)
    }
  return Text
}

const PreferedLocation=()=>{
  let Text=""
  if(formData?.preferred_location?.length>0){
    getCityData?.data?.map((item)=>{
      if(formData?.preferred_location.includes(item.id))
      Text+=item.city+", "
    })
    Text=Text.slice(0,-2)
    }
  return Text

}
  return (
    <div>
      {bankerSearchLoading && <LoaderAPI />}
      
        <Navbar hemBurgerIcon={false}  CompanyNavbar={true} BackBtn={true} ProfileAndNotification={true} />

      <div className="block lg:hidden">
      <SideNavbar />
      </div>

      <div className="lg:flex w-full">
        <div className="w-full hidden h-full lg:block  lg:w-[38%] p-5 light-gray-background">
          <div className="flex px-6 mt-10 mb-5">
            <div className="flex">
              <div>
                <FilterIcon />
              </div>
              <div>
                <h5 className="secondary-text-gray ml-2 text-[22px] font-bold font-lato">
                  Filters
                </h5>
              </div>
            </div>
            <div className="ml-auto" role="button" onClick={handleClearAll}>
              <p className="primary-text-green text-[16px]">Clear all</p>
            </div>
          </div>
          <div className="px-6 my-6"></div>

          <Dropdown subTitle={Tenure()} title="Availability for hire">
            <div className="grid grid-cols-3 my-4">
              {filterAvailHire.map((item, index) => {
                return <Checkbox
                  key={index}
                  id="company-radio"
                  type="checkbox"
                  label={item?.name}
                  value={item?.name}
                  checked={item?.isChecked}
                  onChange={handleAvailabilityHireChange}
                />
              })}
            </div>
          </Dropdown>

          <Dropdown subTitle={ConsultancyMode()} title={`Mode of Consultancy (${[...new Set(formData?.consultancy_mode)]?.length || 0})`}>
            <div className="grid grid-cols-3 my-4">
              {modeOfConsult.map((item, index) => {
                return <Checkbox
                  key={index}
                  id="company-radio"
                  type="checkbox"
                  label={item?.name}
                  value={item?.name}
                  checked={item?.isChecked}
                  onChange={handleAvailabilityHireChange}
                />
              })}
            </div>
          </Dropdown>

          <Dropdown subTitle={Designation()} title={`Designation (${selectedOptions?.length || 0})`}>
            <div className="relative my-2 w-full">
                <SelectTagComponent
                defaultVal="select"
                options={SearchData || []}
                // error={errors.turnoverGrid}
                onChange={(value) => handleOptionSelect(value)}
              />
            </div>

            <Checkbox
              type="checkbox"
              checked={formData?.mandatory_fields?.includes('designation') ? true : false}
              onChange={(e)=>handleMandatory(e,"designation")}
              label="Mark all designations as mandatory"
            />

            <div className="my-4">
              <RoundedBox cross={true} data={selectedOptions} onRemove={handleOptionRemove} />
            </div>
          </Dropdown>

          <Dropdown subTitle={KeyServices()} title={`Key Services (${selectedOptions1?.length || 0})`}>
            <div className="relative my-2 w-full">
            
               <SelectTagComponent
                defaultVal="select"
                options={keyServices || []}
                // error={errors.turnoverGrid}
                onChange={(value) => handleOptionSelect1(value)}
              />
            </div>

            <Checkbox
              type="checkbox"
              label="Mark all Key services as mandatory"
              checked={formData?.mandatory_fields?.includes('service_area') ? true : false}
              onChange={(e)=>handleMandatory(e,"service_area")}
            />

            <div className="my-4">
              <RoundedBox cross={true} data={selectedOptions1} onRemove={handleOptionRemove1} />
            </div>
          </Dropdown>

          <Dropdown subTitle={PreferedLocation()} title={`Preferred job locations (${selectedOptions2?.length || 0})`}>
            <div className="relative my-2 gap-2 w-full flex">

              <SelectTagComponent
                defaultVal={state ? state :"select state"}
                options={getStateData?.data?.map((item)=>item?.name) || []}
                // error={errors.turnoverGrid}
                onChange={(value) => {
                  const id=getStateData?.data?.find((item)=>item?.name===value)?.id;
                  setStateId(id);
                }}
              />
               <SelectTagComponent
                defaultVal={city ? city :"select city"}
                options={getCityData?.data?.map((item)=>item?.city) || []}
                // error={errors.turnoverGrid}
                onChange={(value) =>{
                  const id=getCityData?.data?.find((item)=>item?.city===value)?.id;
                  setCityId(id);
                   handleOptionSelect2(value)
                  }}
              />
            </div>

            <Checkbox
              type="checkbox"
              label="Mark all location as mandatory"
              checked={formData?.mandatory_fields?.includes('preferred_location') ? true : false}
              onChange={(e)=>handleMandatory(e,"preferred_location")}
            />

            <div className="my-4">
              <RoundedBox cross={true} data={selectedOptions2} onRemove={handleOptionRemove2} />
            </div>
          </Dropdown>

          <Dropdown subTitle={EducationLevel()} title={`Level of education (${selectedOptions3?.length || 0})`}>

          <div className="relative my-2 w-full">
                 <SelectTagComponent
                defaultVal="select"
                options={LevelOfEducation || []}
                // error={errors.turnoverGrid}
                onChange={(value) => handleOptionSelect3(value)}
              />
            </div>

            <Checkbox
              type="checkbox"
              label="Mark all education as mandatory"
              checked={formData?.mandatory_fields?.includes('education_level') ? true : false}
              onChange={(e)=>handleMandatory(e,"education_level")}
            />

            <div className="my-4">
              <RoundedBox cross={true} data={selectedOptions3} onRemove={handleOptionRemove3} />
            </div>
         
          </Dropdown>
        </div>

         
        <div className="w-full lg:w-[62%]">
          <div className="light-gray-background rounded-[8px] w-[92%] m-auto lg:py-5 p-4 lg:px-4 lg:mt-8  lg:flex justify-between text-start">
            <div className="w-full lg:w-[70%]">
              <p className="primary-text-gray font-lato">Result for :</p>
              <p className="secondary-text-gray font-lato">
            {bankerSearchData?.recent_searches?.replace(/,\|$/, '')}
              </p>
            </div>
            <div role="button" onClick={handleSaveSearch}>
              <p className="lg:mt-5 primary-text-green">Save Search</p>
            </div>
          </div>

          <div className="px-4 lg:px-10 mt-4 grid lg:grid-cols-2 grid-cols-1" >
            <div className="flex justify-between items-center">
              <div className="flex items-center gap-2 justify-between lg:space-x-2">
                <div>
                  <p className="s3-text">Active in</p>
                </div>
                <div className="w-36">
                <SelectTagComponent
                    defaultVal={getActiveData?.data?.filter((item) => item.id === formData?.active)?.map((item) => item?.value)[0]}
                    options={getActiveData?.data?.map((item)=>{return {...item,name:item?.value}}) || []}
                    onChange={(val)=>handleSelectTimePeriod(val)}
                  />
                </div>
              </div>

              <div onClick={()=>setActiveFilter(!ActiveFilter)} className={`block ${ActiveFilter?'bg-gray-200':''} block px-1 py-1 rounded-lg lg:hidden`}>
                  <FilterIcon/>
                  
                  </div>
            </div>

           {ActiveFilter && <div className="w-full block lg:hidden rounded-lg p-5 light-gray-background">
          <div className="flex px-6 mt-10 mb-5">
            <div className="flex">
              <div>
                <FilterIcon />
              </div>
              <div>
                <h5 className="secondary-text-gray ml-2 text-[22px] font-bold font-lato">
                  Filters
                </h5>
              </div>
            </div>
            <div className="ml-auto" role="button" onClick={handleClearAll}>
              <p className="primary-text-green text-[16px]">Clear all</p>
            </div>
          </div>
          <div className="px-6 my-6"></div>

          <Dropdown subTitle={Tenure()} title="Availability for hire">
            <div className="grid grid-cols-3 my-4">
              {filterAvailHire.map((item, index) => {
                return <Checkbox
                  key={index}
                  id="company-radio"
                  type="checkbox"
                  label={item?.name}
                  value={item?.name}
                  checked={item?.isChecked}
                  onChange={handleAvailabilityHireChange}
                />
              })}
            </div>
          </Dropdown>

          <Dropdown subTitle={ConsultancyMode()} title={`Mode of Consultancy (${[...new Set(formData?.consultancy_mode)]?.length || 0})`}>
            <div className="grid grid-cols-3 my-4">
              {modeOfConsult.map((item, index) => {
                return <Checkbox
                  key={index}
                  id="company-radio"
                  type="checkbox"
                  label={item?.name}
                  value={item?.name}
                  checked={item?.isChecked}
                  onChange={handleAvailabilityHireChange}
                />
              })}
            </div>
          </Dropdown>

          <Dropdown subTitle={Designation()} title={`Designation (${selectedOptions?.length || 0})`}>
            <div className="relative my-2 w-full">
                <SelectTagComponent
                defaultVal="select"
                options={SearchData || []}
                // error={errors.turnoverGrid}
                onChange={(value) => handleOptionSelect(value)}
              />
            </div>

            <Checkbox
              type="checkbox"
              checked={formData?.mandatory_fields?.includes('designation') ? true : false}
              onChange={(e)=>handleMandatory(e,"designation")}
              label="Mark all designations as mandatory"
            />

            <div className="my-4">
              <RoundedBox cross={true} data={selectedOptions} onRemove={handleOptionRemove} />
            </div>
          </Dropdown>

          <Dropdown subTitle={KeyServices()} title={`Key Services (${selectedOptions1?.length || 0})`}>
            <div className="relative my-2 w-full">
            
               <SelectTagComponent
                defaultVal="select"
                options={keyServices || []}
                // error={errors.turnoverGrid}
                onChange={(value) => handleOptionSelect1(value)}
              />
            </div>

            <Checkbox
              type="checkbox"
              label="Mark all Key services as mandatory"
              checked={formData?.mandatory_fields?.includes('service_area') ? true : false}
              onChange={(e)=>handleMandatory(e,"service_area")}
            />

            <div className="my-4">
              <RoundedBox cross={true} data={selectedOptions1} onRemove={handleOptionRemove1} />
            </div>
          </Dropdown>

          <Dropdown subTitle={PreferedLocation()} title={`Preferred job locations (${selectedOptions2?.length || 0})`}>
            <div className="relative my-2 w-full flex">

              <SelectTagComponent
                defaultVal={state ? state :"select state"}
                options={getStateData?.data?.map((item)=>item?.name) || []}
                // error={errors.turnoverGrid}
                onChange={(value) => {
                  const id=getStateData?.data?.find((item)=>item?.name===value)?.id;
                  setStateId(id);
                }}
              />
               <SelectTagComponent
                defaultVal={city ? city :"select city"}
                options={getCityData?.data?.map((item)=>item?.city) || []}
                // error={errors.turnoverGrid}
                onChange={(value) =>{
                  const id=getCityData?.data?.find((item)=>item?.city===value)?.id;
                  setCityId(id);
                   handleOptionSelect2(value)
                  }}
              />
            </div>

            <Checkbox
              type="checkbox"
              label="Mark all location as mandatory"
              checked={formData?.mandatory_fields?.includes('preferred_location') ? true : false}
              onChange={(e)=>handleMandatory(e,"preferred_location")}
            />

            <div className="my-4">
              <RoundedBox cross={true} data={selectedOptions2} onRemove={handleOptionRemove2} />
            </div>
          </Dropdown>

          <Dropdown subTitle={EducationLevel()} title={`Level of education (${selectedOptions3?.length || 0})`}>

          <div className="relative my-2 w-full">
                 <SelectTagComponent
                defaultVal="select"
                options={LevelOfEducation || []}
                // error={errors.turnoverGrid}
                onChange={(value) => handleOptionSelect3(value)}
              />
            </div>

            <Checkbox
              type="checkbox"
              label="Mark all education as mandatory"
              checked={formData?.mandatory_fields?.includes('education_level') ? true : false}
              onChange={(e)=>handleMandatory(e,"education_level")}
            />

            <div className="my-4">
              <RoundedBox cross={true} data={selectedOptions3} onRemove={handleOptionRemove3} />
            </div>
         
          </Dropdown>
        </div>}

            <div className="flex justify-between items-center">
              <div className="flex items-center lg:mt-0 mt-4 space-x-2">
                <div>
                  <p className="s3-text">Show</p>
                </div>
                <div className="w-24">
                <SelectTagComponent defaultVal="5" onChange={handleChange} options={PageOptions} />
                </div>
              </div>
              <div className="flex items-center space-x-2 mt-4 lg:mt-0">
              <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          </div>

          <div className="h-[1px] w-[90%] m-auto mt-8 bg-gray-200"></div>

          <div className= "px-2 md:px-10">
            {currentItems?.length>0 ? currentItems?.map((item, index) => {
              return (
                <ProfileCard
                  key={index}
                  saveIconUI={true}
                  SaveIconShow={true}
                  shortlistBtn={false}
                  hireBtn="Hire Now"
                  type="advance"
                  typeOf="AdvanceSearch"
                  refetch={fetchData}
                 data={item}
                />
              );
            }):
            <DataNotFound title="Bankers Data Not Found" />
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardFilter;
