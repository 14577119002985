import React, { useEffect, useState } from "react";
import Navbar from "../../../../common/Navbar";
import { GreenPencilIcon, PaymentModeIcon, RozerPayIcon } from "../../../../common/Icons";
import {
    BankerPaymentStyeleBRLine,
    BankerPaymentStyeleSubTotal,
  BankerPaymentStyeleSubTotal1,
  BankerPaymentStyeleSubTotalDiv,
  BankerPaymentStyeleTaxDiv,
  BankerPlanStyleContainerTitleGap,
} from "../../../profile/banker/BankerStyle";
import Checkbox from "../../../../common/Checkbox";
import Button from "../../../../common/Button";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FlexJustBtItemCenter, FlexJustitemCenter, ProceedPayStyleMainDiv, ProceedPayStyleReview, ProceedPayStyleReviewButton, ProceedPayStyleReviewDiv, ProceedPayStyleReviewDiv2, ProceedPayStyleReviewDivPTag, S2TextGreen } from "./DashboardJobStyle";
import { flexitemCentre, flexjustifybt } from "../../../../common/StyleCommon";
import { JustStartItemCenter } from "../search/SearchStyle";
import useGetData from "../../../../Hook/useGetData";
import { ToastMessage, formatPriceInput, paymentGatewayKey } from "../../../../common/Config";
import usePost from "../../../../Hook/usePost";
import { convertDate, createSignature, generateSignature, getMonthDifference } from "../../../../Hook/validation";

import  imgLogo from "../../../../common/logo.png"
const ProceedToPay = () => {
  const token=JSON.parse(sessionStorage.getItem("userToken")) || "";
  const {id,bkId,hireId}=useParams()
  const location=useLocation()
  const { data:userData, error, loading, refetch:fetchAgain } = useGetData("profile/company/basic-details", token);
  
  const { data: getHireData, loading: loadingHire, error: errorHire } = useGetData(`hiring/get-job-hire/${bkId}`,token);
  const { data: OrderData,loading:OrderLoading, 
    error: OrderError, postData: postOrderData } = usePost(`payment/create-order`);
    const { data: PaymentData,loading:PaymentLoading, 
      error: PaymentError, postData: postPaymentData } = usePost(`payment/payment-callback`);
  const [data,setData]=useState({})
  const ReviewDetails = [
    {
      id: 1,
      name: "Project name",
      value: getHireData?.data?.project_name || "Not Available",
      subValue: [],
    },
    {
      id: 2,
      name: "Mode of Consultancy",
      value: getHireData?.data?.consultancy_mode?.name || "Not Available",
      subValue: [],
    },
    {
      id: 3,
      name: "Availability for hire",
      value: getHireData?.data?.tenure?.name || "Not Available",
      subValue: [],
    },
    {
      id: 4,
      name: "",
      value: `${convertDate(getHireData?.data?.start_date)} -  ${convertDate(getHireData?.data?.end_date)}`,
      subValue: [],
    },
  ];
const MonthCount=1 || getMonthDifference(getHireData?.data?.start_date,getHireData?.data?.end_date);
const TotalPayOut=(getHireData?.data?.banker?.payout || 0)*MonthCount;
const TotalPayOutGST=TotalPayOut*0.18;
const SubTotal=TotalPayOut+TotalPayOutGST;
const TotalTDS=parseFloat((TotalPayOut*0.1).toFixed(2));
const Total=SubTotal-TotalTDS;
  const MileStoneDetails = getHireData?.data?.milestones?.map((item,index)=>{
    return {
      id:index+1,
      name:`Milestone ${index+1}`,
      value:item?.description,
      subValue:item?.due_date,
    }
  }) || [];
const navigate = useNavigate();
const GST=parseInt(getHireData?.data?.banker?.payout || 0)*MonthCount*0.18;

const options = {
  key:paymentGatewayKey,
  amount: (TotalPayOut || 1)*100*MonthCount, //  = INR 1
  name: "BankerKlub",
  description: "some description",
  image: imgLogo,
  handler: function(response) {
  setData(response)
  postPaymentData({razorpay_payment_id:response.razorpay_payment_id,razorpay_order_id:sessionStorage.getItem("orderId"),razorpay_signature:sessionStorage.getItem('generated_signature'),amount:(TotalPayOut || 1)*MonthCount,job_hire:hireId || location?.state?.hire_id || getHireData?.data?.id},token)
  // navigate("/dashboard/hirenow/paymentstatus",{state:{data:response,predata:getHireData?.data}})
    // alert(response.razorpay_payment_id);
  },
  prefill: {
    name: userData?.data?.company?.user?.name || "Not available",
    contact:  userData?.data?.company?.user?.phone_number || "Not available",
    email: userData?.data?.company?.user?.email || "Not available",
  },
  notes: {
    address: `${userData?.data?.company?.city?.city || "Not available"},${userData?.data?.company?.state?.name || "Not available"}`,
  },
  theme: {
    color: "#FF0059",
    hide_topbar: false
  }
};


const openPayModal = options => {
  var rzp1 = new window.Razorpay(options);
  rzp1.open();
  postOrderData({product_cost:(TotalPayOut || 1)*MonthCount,gst:GST, tds:TotalTDS,job_hire:hireId || location?.state?.hire_id || getHireData?.data?.id,is_payment_onboarding:false},token)
};
useEffect(() => {
  const script = document.createElement("script");
  script.src = "https://checkout.razorpay.com/v1/checkout.js";
  script.async = true;
  document.body.appendChild(script);
}, []);
useEffect(()=>{
if(OrderData){
  sessionStorage.setItem("orderId",OrderData?.order_id);
  sessionStorage.setItem("generated_signature",OrderData?.generated_signature);
  
  // ToastMessage("success","Order placed successfully")
}
if(OrderError){
  ToastMessage("error","Something went wrong")
}
},[OrderData,OrderError])
useEffect(()=>{
  if(PaymentData){
    ToastMessage("success",`Congratulations! You have successfully hired Mr./Ms. ${userData?.company?.user?.name || "Unknown"}. Please download the confirmation letter for your record.`)
    navigate("/dashboard/hirenow/paymentstatus",{state:{data:data,predata:getHireData?.data,PaymentData:PaymentData}})
  }
  if(PaymentError){
    ToastMessage("error","Something went wrong")
  }

},[PaymentData])
const handleEditRedirection=()=>{
  navigate(`/dashboard/hirenow/${id}/${bkId}`,{state:{edit:true,data:getHireData}})
}
  return (
    <div>
      {PaymentLoading &&
        <div class="w-full h-full fixed top-0 left-0 bg-white opacity-100 z-50">
        <div class="flex justify-center items-center mt-[50vh]">
          <div class="fas fa-circle-notch fa-spin fa-5x text-violet-600">
            Loading...
          </div>
        </div>
      </div> 
      }
      <Navbar hemBurgerIcon={true} CompanyNavbar={true} BackBtn={true} ProfileAndNotification={true} />

      <div className={ProceedPayStyleMainDiv}>
        <div className="">
          <div className={flexjustifybt}>
            <div>
              <p className="s1-text">Project Preview</p>
            </div>
            <div className={FlexJustitemCenter} onClick={handleEditRedirection}>
              <GreenPencilIcon className="mt-0.4 mr-2"/>
              <p className={S2TextGreen}>Edit</p>
            </div>
          </div>

          <div className={ProceedPayStyleReviewDiv}>
            {ReviewDetails?.map((item, index) => {
              return (
                <div className="flex my-6 " key={index}>
                  <div className="w-[30%]">
                    <p className={ProceedPayStyleReviewDivPTag}>
                      {item?.name}
                    </p>
                  </div>
                  <div className={BankerPlanStyleContainerTitleGap}>
                    <p className="text-[#2C2C2C]">-</p>
                  </div>

                     <div className="w-[70%] text-start">
                          <p className="s3-text pl-3 text-[#2C2C2C]">
                            {item?.value}
                          </p>
                        </div>
                    
                </div>
              );
            })}
          </div>

          <div className={ProceedPayStyleReview}>
            {MileStoneDetails?.map((item, index) => {
              return (
                <div className="flex my-6  " key={index}>
                  <div className="w-[30%]">
                    <p className={ProceedPayStyleReviewDivPTag}>
                      {item?.name}
                    </p>
                  </div>
                  <div className={BankerPlanStyleContainerTitleGap}>
                    <p className="text-[#2C2C2C]">-</p>
                  </div>

                     <div className="w-[70%] pl-3">
                          <p className="s3-text text-start text-[#2C2C2C]">
                            {item?.value}
                          </p>
                          <p className="s3-text my-4 text-start text-[#2C2C2C]">
                           Due Date - {convertDate(item?.subValue)}
                          </p>
                    </div>
                    
                </div>
              );
            })}
          </div>

          <div className={ProceedPayStyleReviewDiv}>
            
                <div className="flex my-5 ">
                  <div className="w-[30%]">
                    <p className={ProceedPayStyleReviewDivPTag}>
                    Documents
                    </p>
                  </div>
                  <div className={BankerPlanStyleContainerTitleGap}>
                    <p className="text-[#2C2C2C]">-</p>
                  </div>

                     <div className="w-[70%] pl-3">
                     <p className="s3-text text-start text-[#1EB469]">
  {getHireData?.data?.documents?.map((item, index) => (
    <a href={item?.document} key={index} className="text-start text-[#1EB469]">{`Document ${index + 1}, `}</a>
  ))}
</p>

                          
                    </div>
                    
                </div>
              
          </div>
        </div>
        <div className="">
            <div className={JustStartItemCenter}>
                <PaymentModeIcon />
                <p className="s2-text text-[#2C2C2C]" > Payment Mode</p>
            </div>

            <div className={ProceedPayStyleReviewDiv2}>
            
                <div className={flexitemCentre}>
                <Checkbox
                        labelClassName="checkBox-label"
                        type="radio"
                        id="short-term-radio"
                        label="Razorpay"
                        description=""
                        value=""
                        checked="true"
                        onChange=""
                      />
                  </div>
                <div>
                    <RozerPayIcon/>
                </div>
              
          </div>

          <div className={BankerPaymentStyeleSubTotal1}>
        <div className={BankerPaymentStyeleSubTotalDiv}>
          <p className="s2-text">Subtotal</p>
          <p className="s2-text">{`₹ ${formatPriceInput((TotalPayOut|| 0).toString())}`}</p>
        </div>
        <div className={BankerPaymentStyeleTaxDiv}>
          <p className="s2-text">
            Tax <span className="text-[#80828D]">(GST 18%)</span>
          </p>
          <p className="s2-text">{`₹ ${formatPriceInput(TotalPayOutGST.toString())}`}</p>
        </div>
        <div className={BankerPaymentStyeleTaxDiv}>
          <p className="s2-text">(TDS 10%)</p>
          <h5 className="s2-text">{`₹ ${formatPriceInput((parseInt(TotalTDS || 0)).toString())}`}</h5>
        </div>
        <div className={BankerPaymentStyeleBRLine}></div>
        <div className={BankerPaymentStyeleTaxDiv}>
          <p className="s2-text">Payable Amount</p>
          <h5 className="h5-text">{`₹ ${formatPriceInput((parseInt(Total || 0)).toString())}`}</h5>
        </div>
      </div>
      <Button 
      // onClick={()=> navigate("/dashboard/hirenow/paymentstatus")}
       onClick={() => openPayModal(options)} className={ProceedPayStyleReviewButton}>
      Proceed to pay
      </Button>
        </div>
        
      </div>
      <div className="block md:hidden lg:hidden h-[2rem]">

</div>

     
    </div>
  );
};

export default ProceedToPay;
